<template>
  <div>
     <div class="breadcrumbs" data-aos="fade-in">
      <div class="container">
        <h2>{{$t("contact-us")}}</h2>
        <!-- <h4>Est dolorum ut non facere possimus quibusdam </h4> -->
        <p>{{$t("contact-us-intro")}}</p>
      </div>
    </div> 

    <section id="contact" class="contact events">
      <div data-aos="fade-up">
        <iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1985.2337205956414!2d-0.16935269089925375!3d5.645301047183337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9bae9abd3c45%3A0xd952672acb80358!2sAssociation%20of%20African%20Universities!5e0!3m2!1sen!2sgh!4v1641991515932!5m2!1sen!2sgh" frameborder="0" allowfullscreen></iframe>
      </div>

      <div class="container" data-aos="fade-up">

        <div class="row mt-5">

          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>{{$t("location")}}:</h4>
                <p>{{$t("trinity-avenue")}}</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>{{$t("email")}}:</h4>
                <p>tekpani@aau.org | skankomah@aau.org</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>{{$t("call")}}:</h4>
                <p>+233 547 728 975</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            <form  @submit.prevent="send" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" v-model="form.name" class="form-control"  :placeholder="$t('your-name')" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" v-model="form.email" :placeholder="$t('your-email')" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" v-model="form.subject" :placeholder="$t('subject')" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" v-model="form.message" rows="5" :placeholder="$t('message')" required></textarea>
              </div>
              <!-- <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div> -->
              <div class="text-center"><button type="submit">{{$t("send-message")}}</button></div>
            </form>

          </div>

        </div>

      </div>
    </section>

  </div>
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate';
// import { required } from '../utils/validations/validations'
import {toast} from "../utils/alert.util";
export default {
  // components : {ValidationProvider, ValidationObserver},
  metaInfo() {
    return { 
      title: `${this.$t("contact-us")} - ${this.$t("ace-students-awards")}`,
      // htmlAttrs: {
      //   lang: this.$i18n.locale,
      //   amp: undefined
      // },
      meta: [
        { name: 'description', content:  ''},
        { property: 'og:title', content: ""},
        { property: 'og:site_name', content: ''},
        {property: 'og:type', content: 'website'},    
        {name: 'robots', content: 'index,follow'} 
      ]
    }
  },
  data(){
    return {
      form : {},
      // required
    }
  },
  methods : {
    send(){
      this.form.locale = this.$i18n.locale;
      const loading = this.$vs.loading({
        color : "#FF1234",
        type : "circles",
        text : this.$t("processing-please-wait")
      });
      this.$http.post("/contact-us", this.form)
      .then(()=> {
        loading.close();
        toast.show({
            icon : "success",
            title : this.$t("submitted-successful"),
            message : this.$t("email-sent")
        });
      }).catch(e => {
        loading.close();
         toast.show({
            icon : "error",
            title : this.$t("error-occurred"),
            message : this.$t("error-occurred")
        });
        console.log(e)
      })
    }
  }
}
</script>

<style>

</style>